import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, Link } from 'gatsby'
import { rand } from '../functions/Utils'


import Layout from '../components/layout/Layout';
import Header from "../components/layout/Header"
import Arrow from '../images/ui/arrow.svg'
import EmbeddedEntry from './EmbeddedEntry';
import '../css/custom/article.css'
import ArticleNavigation from '../components/knowledgehub/ArticleNavigation';
import ArticleCTA from '../components/knowledgehub/ArticleCTA';
import FloatingContainer from '../components/floats/FloatingContainer';
import FloatingShape from '../components/floats/FloatingShape';



const KnowledgeHubArticle = ({ data, location }) => {
    // Extracting data
    const { title, content, id, slug, metaTitle, metaDescription } = data.contentfulKnowledgeHubArticles

    // Handles the parameters for floats
    // Measures content height to randomly generate X number of floats
    const containerRef = useRef(null)
    const [containerHeight, setContainerHeight] = useState(100)

    useEffect(() => {
        setContainerHeight(containerRef.current.clientHeight)
    }, [containerRef, setContainerHeight])

    const richTextOptions = {
        renderNode: {
            // Deals with embedded images from Rich Text
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const { title, file } = node.data.target.fields;
                return <img src={file["en-US"].url} alt={title} />
            },

            // Creates the block version of the embedded articles
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                const { title, slug } = node.data.target.fields;
                if (title && slug) {
                    return <EmbeddedEntry title={title["en-US"]} link={slug["en-US"]} />
                }
                return <div className="text-white p-4 bg-black text-lg">Error! Link and title required (make sure you are linking a knowledge hub article!)</div>
            },

            // Creates the inline version of the embedded articles
            [INLINES.EMBEDDED_ENTRY]: (node) => {
                const { title, slug } = node.data.target.fields;
                if (title && slug) {
                    return <Link to={"/articles/" + slug["en-US"]}>
                        <span className="p-1 border-2 m-2 text-primary border-secondary shadow-sm hover:shadow-lg transition-quick ">{title["en-US"]} </span>
                    </Link>
                }
                return <span style={{ color: 'red' }}>Error! Link articles only. </span>
            },
            [INLINES.HYPERLINK]: ({ data, content }) => {
                if ((data.uri).includes('player.vimeo.com/video')) {
                    return (
                        <span className="block relative w-full" style={{ paddingBottom: '56.25%' }}>
                            <iframe
                                title={`Vimeo Video ${data.uri}`}
                                src={data.uri}
                                className="h-full w-full absolute top-0 left-0"
                                frameBorder="0"
                                allowFullScreen
                            />
                        </span>
                    );
                }

                return (
                    <a className="hover:underline" target="_blank" href={data.uri} rel="noopener noreferrer">
                        {content.map(({ value }) => value)}
                    </a>
                );
            },
        }
    }


    const renderFloats = (height) => {
        const maxFloats = height / 600
        const renderFloats = Math.floor(maxFloats) - 1

        if (renderFloats < 1) return ""


        const renderArray = Array.from(Array(renderFloats).keys())


        const floatOptions = [
            {
                shape: "polygon",
                size: Math.floor(rand(30, 150))
            }, 
            {
                shape: "circle",
                size:  Math.floor(rand(10, 90))
            }, 
            {
                shape: "triangle",
                size:  Math.floor(rand(30, 150))
            },
            {
                shape: "halfMoon",
                size:  Math.floor(rand(30, 160))
            },
        ]

        return renderArray.map((float, index) => {
        const chosenFloat = floatOptions[Math.floor(Math.random() * 4)]

        return <FloatingContainer
            key={index}
            style={{
                marginTop: index === 0 ? 100 : 0,
                maxHeight: height / 4,
                top: height / renderFloats * (index + 0.5),
                left: index % 2 === 0 ? 0 : "",
                right: index % 2 === 1 ? 0 : ""
            }}
            className="lg:w-1/2 w-full "
            floatSpeed="custom"
            speed={5000}
            floatType="wobbleDrift"
            // rotate={false}
            height="300px"

        >
            <FloatingShape
                height={chosenFloat.size}
                width={chosenFloat.size}
                shape={chosenFloat.shape}
            />
        </FloatingContainer>
        })
    }



    return (
        <Layout
            gutters={false}
            container={false}
            location={location}
        >
            <Header
                title={`${metaTitle ? metaTitle : title} | Kontor`}
                description={`${metaDescription ? metaDescription : title}`}
                canonicalURL={`articles/${slug}`}
            />
            <div className="max-w-6xl mx-auto px-0">

            <Link to="/knowledge-hub" className="flex py-3 pl-6 text-primary text-lg mx-auto md:w-2/3 mt-2 lg:mt-24 lg:px-16">
                <img
                    style={{ transform: 'rotate(-90deg)' }}
                    src={Arrow} alt="Arrow Icon - For going Back"
                    className="mr-2"
                />
                Back
            </Link >

          
            <h1 className="mt-8 md:w-2/3 mx-auto px-8 lg:px-16 text-4xl lg:text-5xl">{title}</h1>

            <div className="relative z-10 px-0 ">



                {renderFloats(containerHeight)}

                <div id="article" className="mb-40 mt-12 article md:w-2/3 " ref={containerRef}>
                    {documentToReactComponents(content.json, richTextOptions)}
                </div>
            </div>

            <ArticleCTA />

            <ArticleNavigation
                currentArticle={id}
            />
  </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulKnowledgeHubArticles(slug: { eq: $slug }) {
        id
        content {
            json
        }
        slug
        title
        metaTitle
        metaDescription
    }
  }
`;

KnowledgeHubArticle.propTypes = {
    data: PropTypes.object.isRequired
}

export default KnowledgeHubArticle
